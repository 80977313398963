import { Energy, Form, FormItem, Gas, IconButton, RadioButton, StyledLink } from '@ten-netzkundenportal/ui-components';
import { InstallerFilterResultWithDecodedCompanyName } from '@ten-netzkundenportal/ui-installer-selection';
import * as React from 'react';

import FormLayout from '../../components/SignupForm/FormLayout';
import { useProcessGuidanceButtonsWithDraft } from '../../components/useProcessGuidanceButtonsWithDraft';
import { ConnectionRequestServiceTypes, RouteProperties } from '../../types';

const ServiceSelectionForm = ({
    onSubmit,
    goBack,
    updateContext,
    context,
    saveDraft,
}: RouteProperties): React.ReactElement<RouteProperties> => {
    const [isPowerSelected, setIsPowerSelected] = React.useState(context.power);
    const [serviceType, setServiceType] = React.useState(context.serviceType ?? 'newConnection');
    const [isGasSelected, setIsGasSelected] = React.useState(context.gas);

    const { leftButton, rightButtonGroup } = useProcessGuidanceButtonsWithDraft({
        onBack: goBack,
        onNext: onSubmit,
        isNextValid: isGasSelected || isPowerSelected,
        saveDraft,
    });

    const electricityResponsibility = context.meta.locationAvailability.electricity.responsible;
    const electricityTenNetworkAccess = context.meta.locationAvailability.electricity.tenNetworkAccess;
    const gasResponsibility = context.meta.locationAvailability.gas.responsible;
    const gasTenNetworkAccess = context.meta.locationAvailability.gas.tenNetworkAccess;

    const determineButtonTitle = (type: 'gas' | 'electricity') => {
        if (
            (type === 'gas' && gasResponsibility !== 'TEN') ||
            (type === 'electricity' && electricityResponsibility !== 'TEN')
        ) {
            return 'Leider können wir Ihnen diesen Service an Ihrem Standort nicht anbieten.';
        }
        return null;
    };

    const getResponsibilityHint = () => {
        const operatingSupplyBranchText = electricityResponsibility === 'OperatingSupply' ? 'Strom' : 'Erdgas';
        const operatingSupplyBranchTextGas = electricityResponsibility === 'OperatingSupply' ? 'Erdgas' : 'Strom';
        const otherBranchText = electricityResponsibility === 'Other' ? 'Strom' : 'Erdgas';

        if (electricityResponsibility === 'OperatingSupply' || gasResponsibility === 'OperatingSupply') {
            return (
                <div className="text-small mt-4">
                    <b>Hinweis:</b> Es tut uns leid, an Ihrem Standort können wir die Beantragung für{' '}
                    {operatingSupplyBranchText} über dieses Portal nicht anbieten. Ihren Netzanschluss für{' '}
                    {operatingSupplyBranchText} können Sie außerhalb des Portals beantragen.
                    <p className="mt-1">
                        {' '}
                        Wenden Sie sich dazu bitte an unsere{' '}
                        <StyledLink href="mailto:netzanschluesse@thueringer-energienetze.com" target="_blank">
                            regionalen Ansprechpartner
                        </StyledLink>
                        . Sofern Sie zuvor einen Antrag für {operatingSupplyBranchTextGas} abgeschlossen haben,
                        verweisen Sie dabei gerne auf Ihre Vorgangsnummer.
                    </p>
                </div>
            );
        }
        if (electricityResponsibility === 'Other' || gasResponsibility === 'Other') {
            return (
                <div className="text-small mt-4">
                    <b>Hinweis:</b> Es tut uns leid, wir sind an Ihrem Standort leider nicht für {otherBranchText}{' '}
                    zuständig. Bitte erkundigen Sie sich nach dem zuständigen Netzbetreiber, z.B. auf{' '}
                    <StyledLink href="https://vnbdigital.de/" target="_blank">
                        VNB Digital
                    </StyledLink>
                    .
                </div>
            );
        }
        return null;
    };

    function resetInstaller(power: boolean, gas: boolean) {
        const installers = context.chosenInstallers ?? [];

        const electricityInstaller = installers.filter((installer) => installer.electricityBranch);

        const gasInstaller = installers.filter((installer) => installer.gasBranch);

        const installerWithNoDuplicate = new Set<InstallerFilterResultWithDecodedCompanyName>();

        if (power) {
            electricityInstaller.forEach((installer) => installerWithNoDuplicate.add(installer));
        }

        if (gas) {
            gasInstaller.forEach((installer) => installerWithNoDuplicate.add(installer));
        }

        updateContext({ chosenInstallers: Array.from(installerWithNoDuplicate) });
    }

    React.useEffect(() => {
        resetInstaller(isPowerSelected, isGasSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPowerSelected, isGasSelected]);

    const handleServiceTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setServiceType(value as ConnectionRequestServiceTypes);
        updateContext({ serviceType: value as ConnectionRequestServiceTypes });
    };
    return (
        <Form title="Benötigter Service" handleSubmit={onSubmit} leftButton={leftButton} rightButton={rightButtonGroup}>
            <FormLayout>
                <FormItem label="Was möchten Sie beantragen?" className="grid 2xl:pl-40 xl:pl-32 md:pr-32">
                    <div className="flex flex-col md:flex-row md:grow gap-y-4 md:gap-x-20">
                        <RadioButton
                            id="new"
                            name="serviceType"
                            value="newConnection"
                            checked={serviceType === 'newConnection'}
                            onChange={handleServiceTypeChange}
                        >
                            Neuanschluss
                        </RadioButton>
                        <RadioButton
                            id="change"
                            name="serviceType"
                            value="adaptConnection"
                            checked={serviceType === 'adaptConnection'}
                            onChange={handleServiceTypeChange}
                            disabled
                        >
                            Anschlussänderung
                        </RadioButton>
                        <RadioButton
                            id="dismantle"
                            name="serviceType"
                            value="dismantleConnection"
                            checked={serviceType === 'dismantleConnection'}
                            onChange={handleServiceTypeChange}
                            disabled
                        >
                            Rückbau
                        </RadioButton>
                    </div>
                    <div className="text-small mt-4">
                        <b>Hinweis:</b> Derzeit können Sie nur den Neuanschluss über das Netzkundenportal beantragen.
                        Für eine Anschlussänderung oder den Rückbau wenden Sie sich bitte direkt an unsere regionalen
                        Ansprechpartner für{' '}
                        <StyledLink
                            href="https://www.thueringer-energienetze.com/Anschluss/Stromnetz/Anschlussaenderung"
                            target="_blank"
                        >
                            Strom
                        </StyledLink>{' '}
                        oder{' '}
                        <StyledLink
                            href="https://www.thueringer-energienetze.com/Anschluss/Erdgasnetz/Anschlussaenderung"
                            target="_blank"
                        >
                            Erdgas
                        </StyledLink>
                        .
                    </div>
                </FormItem>

                <FormItem
                    label="Für welche Sparte(n) möchten Sie den Service?"
                    className="grid 2xl:pl-40 xl:pl-32 md:pr-32"
                >
                    <div className="flex flex-col md:flex-row md:grow gap-y-4 md:gap-x-20">
                        <div className="lg:w-72 w-full h-12">
                            <IconButton
                                name="power"
                                title={determineButtonTitle('electricity')}
                                label="Strom"
                                icon={<Energy />}
                                isInitiallyChecked={isPowerSelected}
                                onClick={() => {
                                    const newPowerValue = !isPowerSelected;
                                    setIsPowerSelected(newPowerValue);
                                    updateContext({ power: newPowerValue }, 'SERVICE_SELECTION');
                                }}
                                disabled={electricityResponsibility !== 'TEN' || !electricityTenNetworkAccess}
                            />
                        </div>
                        <div className="lg:w-72 w-full h-12">
                            <IconButton
                                name="gas"
                                title={determineButtonTitle('gas')}
                                label="Erdgas"
                                padding="1"
                                icon={<Gas />}
                                isInitiallyChecked={isGasSelected}
                                onClick={() => {
                                    const newGasValue = !isGasSelected;
                                    setIsGasSelected(newGasValue);
                                    updateContext({ gas: newGasValue }, 'SERVICE_SELECTION');
                                }}
                                disabled={gasResponsibility !== 'TEN' || !gasTenNetworkAccess}
                            />
                        </div>
                    </div>
                    {getResponsibilityHint()}
                </FormItem>
            </FormLayout>
        </Form>
    );
};
export default ServiceSelectionForm;
