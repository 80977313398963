import * as React from 'react';

interface TwoButtonProps {
    leftButton: React.ReactElement;
    rightButton: React.ReactElement;
}

// TODO: https://sta-teag.atlassian.net/browse/NTW-329
export const TwoButton = ({ leftButton, rightButton }: TwoButtonProps): React.ReactElement => (
    <div className="grid grid-flow-col mt-12 w-full" style={{ height: 'min-content' }}>
        <div className="button place-self-start flex">{leftButton}</div>
        <div className="button place-self-end flex">{rightButton}</div>
    </div>
);
