import React, { useCallback, useEffect } from 'react';

import { UpdateContextFunction } from '../../../types';
import { Context } from '../../../wizard';

export const DRAW_BUILDINGS_STATE = 0;
export const DRAW_MARKERS_STATE = 1;
export const EDIT_LINE_COURSE_STATE = 2;
export const DRAWING_COMPLETE_STATE = 3;

export type ProcessGuideContent = {
    heading: string;
    text: string;
    footer?: React.ReactElement;
};

export const getContent = (currentProcessStep: number, context: Partial<Context>): ProcessGuideContent => {
    switch (currentProcessStep) {
        case DRAW_BUILDINGS_STATE:
            return {
                heading: 'Anschlussobjekt markieren',
                text:
                    context.userType === 'installer' || context.userType === 'projectpartner'
                        ? 'Bitte markieren Sie das Gebäude auf der Karte mit den Werkzeugen, die Sie links sehen.'
                        : 'Bitte markieren Sie Ihr Gebäude auf der Karte mit den Werkzeugen, die Sie links sehen.',

                footer: <>Fertig? Dann klicken Sie auf &bdquo;Weiter&ldquo;.</>,
            };
        case DRAW_MARKERS_STATE:
            return {
                heading: 'Anschlusspunkt einzeichnen',
                text:
                    context.userType === 'installer' || context.userType === `projectpartner`
                        ? 'Bitte wählen Sie links den gewünschten Anschluss aus und markieren damit den geplanten ' +
                          'Anschlusspunkt durch einen Klick auf der Karte.'
                        : 'Bitte wählen Sie links den gewünschten Anschluss aus und markieren damit Ihren geplanten ' +
                          'Anschlusspunkt durch einen Klick auf der Karte. ',

                footer: <>Fertig? Dann klicken Sie auf &bdquo;Weiter&ldquo;.</>,
            };
        case EDIT_LINE_COURSE_STATE:
            if (
                context.power &&
                !context.isElectricityLineCourseValid &&
                context.gas &&
                !context.isGasLineCourseValid
            ) {
                return {
                    heading: 'Leitungsverläufe korrigieren',
                    text:
                        'Bitte verbinden Sie Ihre Leitungen mit den passenden Hauptleitungen für Strom und für ' +
                        'Erdgas.',
                };
            }
            if (context.power && !context.isElectricityLineCourseValid) {
                return {
                    heading: 'Leitungsverlauf korrigieren',
                    text: 'Bitte verbinden Sie Ihre Stromleitung mit einer Hauptleitung für Strom.',
                };
            }
            if (context.gas && !context.isGasLineCourseValid) {
                return {
                    heading: 'Leitungsverlauf korrigieren',
                    text: 'Bitte verbinden Sie Ihre Gasleitung mit einer Hauptleitung für Erdgas.',
                };
            }
            return {
                heading: 'Leitungsverlauf prüfen und anpassen',
                text:
                    'Passt der vorgeschlagene Leitungsverlauf? Falls nein, verändern Sie bitte den Verlauf der ' +
                    'Leitung durch Verschieben der Quadrate. Ihre Leitung muss dabei senkrecht auf die Hauptleitung ' +
                    'treffen.',
            };
        case DRAWING_COMPLETE_STATE:
            return {
                heading: 'Einzeichnen abgeschlossen',
                text:
                    'Geschafft! Ist jetzt alles wie geplant? Wenn ja, gehen Sie bitte nach unten zur nächsten Frage. ' +
                    'Wenn nein, gehen Sie gern zurück und nehmen Anpassungen vor.',
            };
        default:
            return {
                heading: '',
                text: '',
            };
    }
};

const useProcessGuide = (context: Partial<Context>, updateContext: UpdateContextFunction) => {
    const firstProcessStep = DRAW_BUILDINGS_STATE;
    const lastProcessStep = DRAWING_COMPLETE_STATE;

    const [currentProcessStep, setCurrentProcessStep] = React.useState<number>(
        context.meta?.connectionPlanFormState ?? DRAW_BUILDINGS_STATE,
    );

    useEffect(() => {
        if (currentProcessStep < firstProcessStep) {
            setCurrentProcessStep(firstProcessStep);
        }
        if (currentProcessStep > lastProcessStep) {
            setCurrentProcessStep(lastProcessStep);
        }
    }, [currentProcessStep, lastProcessStep, firstProcessStep]);

    useEffect(() => {
        if (currentProcessStep <= EDIT_LINE_COURSE_STATE) {
            return;
        }
        const isElectricityLineCourseInvalid = context.power && !context.isElectricityLineCourseValid;
        const isGasLineCourseInvalid = context.gas && !context.isGasLineCourseValid;

        if (isElectricityLineCourseInvalid || isGasLineCourseInvalid) {
            setCurrentProcessStep(EDIT_LINE_COURSE_STATE);
            updateContext({
                meta: {
                    connectionPlanFormState: EDIT_LINE_COURSE_STATE,
                },
            });
        }
    }, [
        context.gas,
        context.isElectricityLineCourseValid,
        context.isGasLineCourseValid,
        context.power,
        currentProcessStep,
        updateContext,
    ]);

    const [loading, setLoading] = React.useState(false);

    const canContinue = useCallback(() => {
        switch (currentProcessStep) {
            case DRAW_BUILDINGS_STATE: {
                return context.houseShapes.length > 0;
            }
            case DRAW_MARKERS_STATE: {
                if (context.multiEntry === 'no') {
                    return (
                        context.power === (context.powerMarker !== undefined) &&
                        context.gas === (context.gasMarker !== undefined)
                    );
                }
                return context.multiEntryMarker !== undefined;
            }
            case EDIT_LINE_COURSE_STATE: {
                return (
                    (!context.power || context.isElectricityLineCourseValid) &&
                    (!context.gas || context.isGasLineCourseValid)
                );
            }
            case DRAWING_COMPLETE_STATE: {
                return false;
            }
            default:
                return false;
        }
    }, [
        currentProcessStep,
        context.houseShapes.length,
        context.multiEntry,
        context.multiEntryMarker,
        context.power,
        context.powerMarker,
        context.gas,
        context.gasMarker,
        context.isElectricityLineCourseValid,
        context.isGasLineCourseValid,
    ]);

    const next = () => {
        if (currentProcessStep < lastProcessStep && canContinue) {
            updateContext({
                meta: {
                    connectionPlanFormState: currentProcessStep + 1,
                },
            });
            setCurrentProcessStep((previousProcessStep) => previousProcessStep + 1);
        }
    };
    const prev = () => {
        if (currentProcessStep > firstProcessStep) {
            updateContext({
                meta: {
                    connectionPlanFormState: currentProcessStep - 1,
                },
            });
            setCurrentProcessStep((previousProcessStep) => previousProcessStep - 1);
        }
    };

    const [showProcessGuide, setShowProcessGuide] = React.useState<boolean>(true);
    const toggleShowProcessGuide = () => {
        setShowProcessGuide((previousShowProcessGuide) => !previousShowProcessGuide);
    };

    return {
        isAtBeginning: currentProcessStep === firstProcessStep,
        isAtEnd: currentProcessStep === lastProcessStep,
        currentProcessStep,
        guideContent: getContent(currentProcessStep, context),
        next,
        prev,
        showProcessGuide,
        toggleShowProcessGuide,
        canContinue,
        setLoading,
        loading,
    };
};

export default useProcessGuide;
