import { useIsAuthenticated } from '@azure/msal-react';
import * as React from 'react';
import { useEffect } from 'react';

import {
    createInitialRequestForAuthenticatedUser,
    createInitialRequestForUnauthenticatedUser,
} from '../../api/createInitialRequest';
import useApi from '../../hooks/useApi';
import { APIRequestState, ConnectionRequestId, Offer, UpdateContextFunction } from '../../types';
import { Context } from '../../wizard';
import contextTransformer from './ContextTransformer';
import { ConnectionRequest, OfferResponse } from './types';
import { updateOfferForAuthenticatedUser, updateOfferForUnauthenticatedUser } from './updateOffer';

export const useOffer = (
    context: Partial<Context>,
    updateContext: UpdateContextFunction,
): { offer: OfferResponse; status: APIRequestState } => {
    const [offer, setOffer] = React.useState<OfferResponse>();
    const [status, setStatus] = React.useState<APIRequestState>('initial');

    const isAuthenticated = useIsAuthenticated();

    const createInitialRequestApi = useApi(
        isAuthenticated ? createInitialRequestForAuthenticatedUser : createInitialRequestForUnauthenticatedUser,
    );
    const updateOfferApi = useApi(
        isAuthenticated ? updateOfferForAuthenticatedUser : updateOfferForUnauthenticatedUser,
    );

    const {
        meta: { connectionRequestId },
        ...nonMetaContext
    } = context;

    useEffect(() => {
        let canceled = false;
        const connectionRequest: ConnectionRequest = contextTransformer(nonMetaContext);
        const calculateOfferAndSet = async (): Promise<void> => {
            let selectedConnectionRequestId: string | undefined = connectionRequestId;
            setStatus('loading');

            if (!selectedConnectionRequestId) {
                const initialRequst = await createInitialRequestApi({ userType: context.userType });
                selectedConnectionRequestId = initialRequst.connectionRequestId;
                updateContext({
                    meta: {
                        connectionRequestId: selectedConnectionRequestId,
                    },
                });
            }

            const response = await updateOfferApi({
                connectionRequest,
                connectionRequestId: selectedConnectionRequestId,
            });

            if (canceled) {
                return;
            }

            setOffer(response.offer);

            const metaUpdate: Partial<Offer> & Partial<ConnectionRequestId> = {
                isAutomaticElectricityOfferPossible: response.offer.isElectricityOfferAutomaticallyCalculated,
                isAutomaticGasOfferPossible: response.offer.isGasOfferAutomaticallyCalculated,
            };

            if (response.offer.isElectricityOfferAutomaticallyCalculated !== undefined) {
                metaUpdate.electricityOffer = {
                    processCommunicationId: response.offer.electricityOffer.processCommunicationId,
                };
            }

            if (response.offer.isGasOfferAutomaticallyCalculated !== undefined) {
                metaUpdate.gasOffer = {
                    processCommunicationId: response.offer.gasOffer.processCommunicationId,
                };
            }
            updateContext({
                meta: {
                    ...metaUpdate,
                },
            });
            setStatus('finished_successfully');
        };

        calculateOfferAndSet().catch((e) => {
            console.error('Failed to calculate offer!', e);
            setOffer(undefined);
            setStatus('error');
        });

        return () => {
            canceled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(nonMetaContext), updateContext]);

    return {
        offer,
        status,
    };
};
