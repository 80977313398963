import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CONTACT_ADDRESS, INVOICE_ADDRESS } from '../routes';
import { RouteProperties } from '../types';
import ContactAddressForm from './contact-address-form';
import InvoiceAddressContainer from './invoice-address-form/InvoiceAddressContainer';

export default (routeProperties: RouteProperties): React.ReactElement => (
    <Switch>
        <Route path={CONTACT_ADDRESS}>
            <ContactAddressForm {...routeProperties} />
        </Route>
        <Route path={INVOICE_ADDRESS}>
            <InvoiceAddressContainer {...routeProperties} />
        </Route>
    </Switch>
);
