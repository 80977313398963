import { CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';

import { transformContextToContactData } from '../addresses/contact-address-form/transformContextToContactData';
import { transformContextToBuilding } from '../consumption/building-data-form/transformContextToBuilding';
import { ConnectionRequest } from '../offer/api/types';
import transformContextLocationToCadastralDistrictLocation from '../service/location-data-form/transformContextLocationToCadastralDistrictLocation';
import {
    CadastralDistrict,
    isBusinessDataCompany,
    isBusinessDataPrivate,
    isCompanyData,
    isPrivateData,
} from '../types';
import { ContactDataRequest } from '../types/connectionRequest/contactData';
import { ServerAddressLocation } from '../types/locationData';
import { Context } from '../wizard/context';

export type DraftDashboardInfo = Partial<Pick<ConnectionRequest, 'serviceType' | 'contactData' | 'building'>> & {
    location: ServerAddressLocation | CadastralDistrictLocation;
};

export const shouldSaveContactData = (context: Partial<Context>): boolean => {
    if (
        isPrivateData(context) &&
        context.privateData?.person.salutation &&
        context.privateData?.person.firstName &&
        context.privateData?.person.lastName
    ) {
        return true;
    }
    if (isCompanyData(context) && context.companyData?.companyName) {
        return true;
    }
    if (
        isBusinessDataPrivate(context) &&
        context.businessData?.person.salutation &&
        context.businessData?.person.firstName &&
        context.businessData?.person.lastName
    ) {
        return true;
    }
    if (isBusinessDataCompany(context) && context.businessData?.companyName) {
        return true;
    }

    return false;
};

const transformContextToDashboardContactData = (context: Omit<Partial<Context>, 'meta'>): ContactDataRequest => {
    if (!shouldSaveContactData(context)) {
        return undefined;
    }

    const contactData = transformContextToContactData(context);

    if (!context.telephone.countryPrefix || !context.telephone.number) {
        contactData.telephone = undefined;
    }

    return {
        ...contactData,
        email: undefined,
        address: undefined,
    };
};

const shouldSaveBuildingData = (context: Omit<Partial<Context>, 'meta'>) => {
    if (context.building?.buildingType === 'special' && !context.building?.specialType) {
        return false;
    }
    return !!context.building && !!context.building?.buildingType && !!context.building?.newBuilding;
};

export type DraftCadastralDistrict = Pick<CadastralDistrict, 'location'>;

export const transformDraftRequestToDashboard = (context: Omit<Partial<Context>, 'meta'>): DraftDashboardInfo => ({
    serviceType: context.serviceType,
    building: shouldSaveBuildingData(context) ? transformContextToBuilding(context) : undefined,
    location: context.location ? transformContextLocationToCadastralDistrictLocation(context) : undefined,
    contactData: transformContextToDashboardContactData(context),
});
