import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { OFFER_CONCLUSION, OFFER_GAS, OFFER_POWER, REQUEST_OVERVIEW } from '../routes';
import { RouteProperties } from '../types';
import { MaterialList } from './api/material-costs/MaterialPriceAPI';
import { useOffer } from './api/useOffer';
import OfferConclusionForm from './offer-conclusion-form';
import OfferGasForm from './offer-gas-form';
import OfferPowerForm from './offer-power-form';
import RequestOverviewForm from './request-overview-form';

export type OfferProperties = RouteProperties & { materialList: MaterialList };

export default ({ materialList, ...routeProperties }: OfferProperties): React.ReactElement => {
    const { context, updateContext } = routeProperties;
    const { offer, status } = useOffer(context, updateContext);

    return (
        <Switch>
            <Route path={REQUEST_OVERVIEW}>
                <RequestOverviewForm offerRequestState={status} {...routeProperties} />
            </Route>
            <Route path={OFFER_POWER}>
                {offer && offer.isElectricityOfferAutomaticallyCalculated && (
                    <OfferPowerForm
                        electricityOffer={offer.electricityOffer}
                        materialList={materialList}
                        {...routeProperties}
                    />
                )}
            </Route>
            <Route path={OFFER_GAS}>
                {offer && offer.isGasOfferAutomaticallyCalculated && (
                    <OfferGasForm gasOffer={offer.gasOffer} materialList={materialList} {...routeProperties} />
                )}
            </Route>
            <Route path={OFFER_CONCLUSION}>
                {offer && <OfferConclusionForm offer={offer} materialList={materialList} {...routeProperties} />}
            </Route>
        </Switch>
    );
};
