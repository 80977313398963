import { ErrorBox, Form } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useProcessGuidanceButtonsWithDraft } from '../../components/useProcessGuidanceButtonsWithDraft';
import { APIRequestState, ElectricityPosition, GasPosition, RouteProperties } from '../../types';
import AddressColumn from './AddressColumn';
import ConnectionColumn from './ConnectionColumn';
import ConsumptionColumn from './ConsumptionColumn';
import ServiceColumn from './ServiceColumn';
import TranspositionColumn from './TranspositionColumn';

export type FormProperties = {
    offerRequestState: APIRequestState;
};

export default ({
    offerRequestState,
    onSubmit,
    goBack,
    context,
    saveDraft,
}: RouteProperties & FormProperties): React.ReactElement => {
    const reactHookForm = useForm<ElectricityPosition & GasPosition>({
        mode: 'all',
        shouldUnregister: true,
    });
    const { handleSubmit } = reactHookForm;

    const [requestOverviewError, setRequestOverviewError] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);

    useEffect(() => {
        if (offerRequestState === 'error') {
            setRequestOverviewError(true);
            setShowError(true);
        } else {
            setRequestOverviewError(false);
            setShowError(false);
        }
    }, [offerRequestState]);

    const { leftButton, rightButtonGroup } = useProcessGuidanceButtonsWithDraft({
        saveDraft,
        onNext: handleSubmit(onSubmit),
        isNextValid: offerRequestState === 'finished_successfully',
        loading: offerRequestState === 'loading',
        onBack: goBack,
        loadingTitle: 'Ihr Antrag wird gerade geprüft.',
    });

    return (
        <Form leftButton={leftButton} rightButton={rightButtonGroup} title="Antragsübersicht">
            <div className="flex flex-col gap-y-4">
                <div>
                    {context.userType === 'installer' || context.userType === 'projectpartner' ? (
                        <span>
                            Bitte prüfen Sie, ob Ihre Angaben korrekt sind, so dass wir Ihrem Kunden auf dieser Basis
                            ein Angebot erstellen können.
                        </span>
                    ) : (
                        <span>
                            Bitte prüfen Sie, ob Ihre Angaben korrekt sind, so dass wir Ihnen auf dieser Basis ein
                            Angebot erstellen können.
                        </span>
                    )}
                </div>

                <div
                    id="columnContainer"
                    className={`grid grid-flow-col overflow-x-scroll custom-scrollbar gap-x-4 pb-4 items-stretch 
                    grid-cols-[repeat(5,24rem)] form`}
                >
                    <ServiceColumn context={context} />
                    <ConsumptionColumn context={context} />
                    <ConnectionColumn context={context} />
                    <TranspositionColumn context={context} />
                    <AddressColumn context={context} />
                </div>

                {requestOverviewError && showError && <ErrorBox onClick={() => setShowError(false)} />}
            </div>
        </Form>
    );
};
