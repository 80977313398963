import { UpdateContextFunction } from '../types';
import { transformDraftRequestToDashboard } from '../util/transformDraftRequestToDashboard';
import { Context } from '../wizard/context';
import { CreateInitialRequestReturnType } from './createInitialRequest';
import { UpdateDraftRequestParams } from './updateDraftRequest';

export type SaveDraftParams = {
    draftContext: Partial<Context>;
    updateContext: UpdateContextFunction;
    createInitialRequest: () => Promise<CreateInitialRequestReturnType>;
    updateDraftRequest: (params?: UpdateDraftRequestParams) => Promise<any>;
};

export const saveDraftFunction = async ({
    draftContext,
    updateContext,
    createInitialRequest,
    updateDraftRequest,
}: SaveDraftParams) => {
    const context = { ...draftContext };

    if (context.meta?.connectionRequestId === undefined) {
        const request = await createInitialRequest();
        context.meta = {
            ...draftContext.meta,
            connectionRequestId: request.connectionRequestId,
        };
        updateContext({
            meta: {
                connectionRequestId: request.connectionRequestId,
            },
        });
    }

    await updateDraftRequest({
        draftRequest: context,
        draftRequestDashboardInfos: transformDraftRequestToDashboard(context),
        requestId: context.meta.connectionRequestId,
    });
};
