import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { MaterialList } from '../offer/api/material-costs/MaterialPriceAPI';
import { INSTALLER_SELECTION, PERSONAL_CONTRIBUTION } from '../routes';
import { RouteProperties } from '../types';
import InstallerSelectionForm from './installer-selection-form';
import PersonalContributionForm from './personal-contribution-form';

export type TranspositionProps = {
    materialList: MaterialList;
};

export default ({ materialList, ...routeProperties }: RouteProperties & TranspositionProps): React.ReactElement => (
    <Switch>
        <Route path={PERSONAL_CONTRIBUTION}>
            <PersonalContributionForm {...routeProperties} materialList={materialList} />
        </Route>
        <Route path={INSTALLER_SELECTION}>
            <InstallerSelectionForm {...routeProperties} />
        </Route>
    </Switch>
);
