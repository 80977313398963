import { LOCATION_DATA } from '../routes';
import { ALL_STEPS } from '../wizard';
import { Context } from '../wizard/context';
import { requestFormVersion } from '../wizard/version';

export const contextMapper = (context: Partial<Context>): Partial<Context> => ({
    ...context,
    meta: {
        ...context.meta,
        furthestStep: { furthestProcess: ALL_STEPS[0], furthestSubprocess: 1 },
        requestFormVersion,
        path: LOCATION_DATA,
    },
});
