import { AuthenticatedContent } from '@ten-netzkundenportal/ui-auth-utils';
import { LoadingSpinner } from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import '@ten-netzkundenportal/ui-installer-selection/dist/base.css';
import * as React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AppRouting from './app-routing.component';
import './base.css';
import { LandingPage } from './landing-page/LandingPage';
import Maintenance from './maintenance/Maintenance';
import NotResponsible from './notResponsible';
import OfferRevisited from './offer/offer-revisited-form';
import { LANDING_PAGE, MAINTENANCE, NOT_RESPONSIBLE, OFFER_REVISITED, START } from './routes';
import Start from './start';

export default (): React.ReactElement => (
    <div className="grid place-items-center gap-y-5 h-fit relative">
        <React.Suspense
            fallback={
                <div className="w-full h-[50rem] flex flex-col items-center">
                    <div className=" flex flex-row min-h-full flex-grow items-center">
                        <div className="w-20 h-20">
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
            }
        >
            <Router>
                <Switch>
                    <Route path={NOT_RESPONSIBLE}>
                        <NotResponsible />
                    </Route>
                    <Route exact path={MAINTENANCE}>
                        <Maintenance />
                    </Route>
                    <Route path={LANDING_PAGE}>
                        <AuthenticatedContent>
                            <LandingPage />
                        </AuthenticatedContent>
                    </Route>
                    <Route exact path={START}>
                        <Start />
                    </Route>
                    <Route path={OFFER_REVISITED}>
                        <AuthenticatedContent>
                            <OfferRevisited />
                        </AuthenticatedContent>
                    </Route>
                    <Route>
                        <AppRouting />
                    </Route>
                </Switch>
            </Router>
        </React.Suspense>
    </div>
);
