import { Step } from '@ten-netzkundenportal/ui-components';
import { useMachine } from '@xstate/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import H from 'history';
import * as React from 'react';
import { matchPath } from 'react-router-dom';
import { AnyStateMachine, Interpreter, StateFrom, createMachine } from 'xstate';

import {
    BUILDING_DATA,
    CONNECTION_DATA,
    CONNECTION_PLAN,
    CONTACT_ADDRESS,
    GAS_CONSUMPTION,
    INSTALLER_SELECTION,
    INVOICE_ADDRESS,
    LAND_OWNER,
    LOCATION_DATA,
    OFFER_CONCLUSION,
    OFFER_GAS,
    OFFER_POWER,
    PERSONAL_CONTRIBUTION,
    POWER_CONSUMPTION_DECLARATION,
    POWER_CONSUMPTION_TOTAL,
    REQUEST_OVERVIEW,
    SERVICE_SELECTION,
} from '../routes';
import actions from './actions';
import { ALL_STEPS, STEPS } from './constants';
import { Context } from './context';
import { SYNC_STATE_WITH_LOCATION, SyncStateWithLocationEvent, UserEvent } from './events';
import guards from './guards';
import initialContext from './initialContext';
import config from './machine';
import { debounce, getRoutes } from './util';
import { requestFormVersion } from './version';

const createInitialSteps = (history: H.History) => {
    const processFunction = (path) => () => {
        history.push(path);
    };

    const step1: Step = {
        label: 'Service',
        processFunctions: [processFunction(LOCATION_DATA), processFunction(SERVICE_SELECTION)],
        steplabels: ['Standort', 'Serviceauswahl'],
        processName: STEPS.SERVICE_PROCESS,
        visible: [true, true],
    };
    const step2: Step = {
        label: 'Leistungsbedarf',
        processFunctions: [
            processFunction(BUILDING_DATA),
            processFunction(POWER_CONSUMPTION_DECLARATION),
            processFunction(POWER_CONSUMPTION_TOTAL),
            processFunction(GAS_CONSUMPTION),
        ],
        steplabels: [
            'Gebäudedaten',
            'Infrastruktur Strom Angaben',
            'Infrastruktur Strom Gesamt',
            'Infrastruktur Erdgas',
        ],
        processName: STEPS.CONSUMPTION_PROCESS,
        visible: [true, true, true, false],
    };
    const step3: Step = {
        label: 'Anschluss',
        processFunctions: [
            processFunction(CONNECTION_DATA),
            processFunction(CONNECTION_PLAN),
            processFunction(LAND_OWNER),
        ],
        steplabels: ['Anschlussdaten', 'Anschlussplan', 'Eigentümerdaten'],
        processName: STEPS.CONNECTION_PROCESS,
        visible: [true, true, true],
    };
    const step4: Step = {
        label: 'Umsetzung',
        processFunctions: [processFunction(PERSONAL_CONTRIBUTION), processFunction(INSTALLER_SELECTION)],
        steplabels: ['Eigenleistungen', 'Installateur'],
        processName: STEPS.TRANSPOSITION_PROCESS,
        visible: [true, true],
    };
    const step5: Step = {
        label: 'Adressen',
        processFunctions: [processFunction(CONTACT_ADDRESS), processFunction(INVOICE_ADDRESS)],
        steplabels: ['Kundendaten', 'Rechnungsdaten'],
        processName: STEPS.ADDRESSES_PROCESS,
        visible: [true, true],
    };
    const step6: Step = {
        label: 'Angebot',
        processFunctions: [
            processFunction(REQUEST_OVERVIEW),
            processFunction(OFFER_POWER),
            processFunction(OFFER_GAS),
            processFunction(OFFER_CONCLUSION),
        ],
        steplabels: ['Antragsübersicht', 'Angebot Strom', 'Angebot Erdgas', 'Abschluss'],
        processName: STEPS.OFFER_PROCESS,
        visible: [true, true, false, true],
    };
    const step7: Step = {
        label: 'Nächste Schritte',
        processFunctions: [],
        steplabels: [],
        processName: STEPS.NEXT_STEPS_PROCESS,
        visible: [true],
    };

    return [step1, step2, step3, step4, step5, step6, step7];
};

export const createWizardMachine = (history: H.History, currentPath: string) => {
    const routes = getRoutes(config).map((route) => ({
        target: route.target,
        cond: (context: Context, event: SyncStateWithLocationEvent) => {
            const { path, process, subProcess } = route;
            const contextProcessIndex = ALL_STEPS.findIndex(
                (step) => step === context.meta.furthestStep.furthestProcess,
            );
            const contextSubProcess = context.meta.furthestStep.furthestSubprocess;

            const processIndex = ALL_STEPS.findIndex((step) => step === process);

            if (processIndex === -1) return false;

            const isEventLocationLessThanContextLocation =
                contextProcessIndex > processIndex ||
                (contextProcessIndex === processIndex && contextSubProcess > subProcess);

            if (!isEventLocationLessThanContextLocation) {
                return contextProcessIndex === processIndex && contextSubProcess === subProcess;
            }

            return !!(
                event.path &&
                matchPath(event.path, { path, exact: true, strict: false }) &&
                context.meta.path !== path
            );
        },
    }));
    const rehydratedConfig = JSON.parse(localStorage.getItem('antragsstrecke')) || {
        ...initialContext,
        meta: { furthestStep: { furthestProcess: ALL_STEPS[0], furthestSubprocess: 1 }, requestFormVersion },
    };
    const machine = {
        ...config,
        ...{
            context: {
                ...rehydratedConfig,
                ...{ meta: { ...rehydratedConfig.meta, ...{ history, path: currentPath } } },
            },
        },
    };
    machine.on[SYNC_STATE_WITH_LOCATION] = routes;
    machine.context.meta.steps = createInitialSteps(history);

    return createMachine<Partial<Context>, UserEvent>(machine, { guards, actions });
};

export default (
    history: H.History,
    path: string,
): [
    StateFrom<AnyStateMachine>,
    Interpreter<Partial<Context>, unknown, UserEvent>['send'],
    Interpreter<Partial<Context>, unknown, UserEvent>,
] => {
    // eslint-disable-next-line
    const machine: AnyStateMachine = React.useMemo(() => createWizardMachine(history, path), []);
    const [state, send, service] = useMachine(machine);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateLocalStorage = React.useCallback(
        debounce((context: Context) => {
            const storedContext = {
                ...(context as Partial<Context>),
                ...{
                    meta: {
                        ...context.meta,
                        ...{ steps: {} },
                    },
                },
            };
            localStorage.setItem('antragsstrecke', JSON.stringify(storedContext));
        }, 500),
        [],
    );

    React.useEffect(() => {
        updateLocalStorage(state.context);
    }, [state.context, updateLocalStorage]);

    return [state, send, service];
};
