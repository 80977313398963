import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { CONNECTION_DATA, CONNECTION_PLAN } from '../routes';
import { RouteProperties } from '../types';
import ConnectionDataForm from './connection-data-form';
import ConnectionPlanForm from './connection-plan-form';
import LandOwnerForm from './land-owner-form';

export default (routeProperties: RouteProperties): React.ReactElement => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={CONNECTION_DATA}>
                <ConnectionDataForm {...routeProperties} />
            </Route>
            <Route path={CONNECTION_PLAN}>
                <ConnectionPlanForm {...routeProperties} />
            </Route>
            <Route path={`${path}/eigentuemer`}>
                <LandOwnerForm {...routeProperties} />
            </Route>
        </Switch>
    );
};
