import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { BUILDING_DATA, GAS_CONSUMPTION, POWER_CONSUMPTION_DECLARATION, POWER_CONSUMPTION_TOTAL } from '../routes';
import { RouteProperties } from '../types';
import BuildingDataForm from './building-data-form';
import GasConsumption from './gas-consumption-form';
import PowerConsumptionDeclarationForm from './power-consumption-declaration-form';
import PowerConsumptionTotalForm from './power-consumption-total-form';

export default (routeProperties: RouteProperties): React.ReactElement => (
    <Switch>
        <Route path={BUILDING_DATA}>
            <BuildingDataForm {...routeProperties} />
        </Route>
        <Route path={POWER_CONSUMPTION_DECLARATION}>
            <PowerConsumptionDeclarationForm {...routeProperties} />
        </Route>
        <Route path={POWER_CONSUMPTION_TOTAL}>
            <PowerConsumptionTotalForm {...routeProperties} />
        </Route>
        <Route path={GAS_CONSUMPTION}>
            <GasConsumption {...routeProperties} />
        </Route>
    </Switch>
);
