import axios from 'axios';

import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';
import { Params } from '../../types/Params';
import { ConnectionRequest, ConnectionRequestResponse } from './types';

const crfServiceUrl = `${appConfig.services.connectionRequestFormApi}`;

type UpdateOfferParams = { connectionRequestId: string; connectionRequest: ConnectionRequest };

export const updateOfferForAuthenticatedUser = async ({
    axios: authenticatedAxios,
    customerId,
    params,
}: ApiParams<UpdateOfferParams>) => {
    const response = await authenticatedAxios.put<ConnectionRequestResponse>(
        `${crfServiceUrl}/customer/${customerId}/connection-request/${params.connectionRequestId}`,
        params.connectionRequest,
    );
    return response.data;
};

export const updateOfferForUnauthenticatedUser = async ({ params }: Params<UpdateOfferParams>) => {
    const response = await axios.put<ConnectionRequestResponse>(
        `${crfServiceUrl}/connection-request/${params.connectionRequestId}`,
        params.connectionRequest,
    );
    return response.data;
};
