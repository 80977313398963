import { Form, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

import { ClosedQuestionsWrapper } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { RouteProperties } from '../../types';
import PowerConsumptionKnown from './PowerConsumptionKnown';
import PowerConsumptionUnknown from './power-consumption-unknown';

export default (routeProperties: RouteProperties): React.ReactElement => {
    const { context, updateContext } = routeProperties;
    const [powerConsumptionKnown, setPowerConsumptionKnown] = React.useState<boolean>(
        context.powerConsumptionKnown ?? false,
    );

    return (
        <Form title="Leistungsbedarf Strom">
            <FormLayout>
                <ClosedQuestionsWrapper
                    label={
                        context.userType === 'installer' || context.userType === 'projectpartner'
                            ? 'Wissen Sie bereits, welche Leistung Ihr Kunde insgesamt benötigen wird?'
                            : 'Wissen Sie bereits, welche Leistung Sie insgesamt benötigen werden?'
                    }
                    gridTemplateColumns="1fr 4fr"
                >
                    <RadioButton
                        name="consumptionKnown"
                        id="consumptionKnown-yes"
                        value="yes"
                        checked={powerConsumptionKnown}
                        onChange={() => {
                            setPowerConsumptionKnown(true);
                            updateContext({ powerConsumptionKnown: true }, 'CONSUMPTION_POWER_CONSUMPTION_DECLARATION');
                        }}
                    >
                        ja
                    </RadioButton>

                    <RadioButton
                        name="consumptionKnown"
                        id="consumptionKnown-no"
                        value="no"
                        checked={!powerConsumptionKnown}
                        onChange={() => {
                            setPowerConsumptionKnown(false);
                            updateContext(
                                { powerConsumptionKnown: false },
                                'CONSUMPTION_POWER_CONSUMPTION_DECLARATION',
                            );
                        }}
                    >
                        nein
                    </RadioButton>
                </ClosedQuestionsWrapper>
            </FormLayout>
            {powerConsumptionKnown && <PowerConsumptionKnown {...routeProperties} />}
            {!powerConsumptionKnown && <PowerConsumptionUnknown {...routeProperties} />}
        </Form>
    );
};
