import { useIsAuthenticated } from '@azure/msal-react';
import {
    CreateProcessGuidanceButtonsProps,
    ErrorBox,
    LoadingSpinnerButton,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { APIRequestState } from '../types';

export type DraftButtonProps = {
    saveDraft: () => Promise<void>;
    isSaveDraftEnabled?: boolean;
};

export const useProcessGuidanceButtonsWithDraft = ({
    saveDraft,
    isSaveDraftEnabled = true,
    ...props
}: CreateProcessGuidanceButtonsProps & DraftButtonProps): {
    rightButtonGroup: React.ReactElement;
    leftButton: React.ReactElement;
} => {
    const isAuthenticated = useIsAuthenticated();

    const [saveDraftState, setSaveDraftState] = React.useState<APIRequestState>('initial');

    const { nextButton, prevButton } = createProcessGuidanceButtons(props);
    const rightButtonGroup = (
        <div className="flex space-x-4">
            <ErrorBox initialShowError={saveDraftState === 'error'} />
            {isAuthenticated && (
                <LoadingSpinnerButton
                    loading={saveDraftState === 'loading'}
                    type="secondary"
                    label="Entwurf speichern"
                    title="Entwurf speichern"
                    onClick={() => {
                        setSaveDraftState('loading');
                        saveDraft()
                            .then(() => setSaveDraftState('finished_successfully'))
                            .catch(() => setSaveDraftState('error'));
                    }}
                    disabled={!isSaveDraftEnabled || saveDraftState === 'loading'}
                />
            )}
            {nextButton}
        </div>
    );

    return { rightButtonGroup, leftButton: prevButton };
};
