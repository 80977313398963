import { ErrorBox, WizardStep } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';

import Addresses from './addresses';
import { createInitialRequestForAuthenticatedUser } from './api/createInitialRequest';
import { saveDraftFunction } from './api/saveDraftFunction';
import { updateDraftRequest } from './api/updateDraftRequest';
import { DraftSavedNotification } from './components/DraftSavedNotification';
import Connection from './connection';
import Consumption from './consumption';
import useApi from './hooks/useApi';
import NextStepsForm from './nextSteps';
import Offer from './offer';
import getMaterialList, { MaterialList } from './offer/api/material-costs/MaterialPriceAPI';
import { ADDRESSES, CONNECTION, CONSUMPTION, NEXT_STEPS, OFFER, SERVICE, TRANSPOSITION } from './routes';
import Service from './service';
import Transposition from './transposition';
import { APIRequestState, RouteProperties } from './types';
import {
    Context,
    NEXT_EVENT,
    PREV_EVENT,
    SUBPROCESS_VALUE,
    SYNC_LOCATION_WITH_STATE,
    SYNC_STATE_WITH_LOCATION,
    useWizardMachine,
} from './wizard';
import { getFurthestSteps } from './wizard/getFurthestSteps';

const AppRouting = (): React.ReactElement => {
    const location = useLocation();
    const history = useHistory();
    const [materialList, setMaterialList] = React.useState<MaterialList>();
    const [updateDraftRequestState, setUpdateDraftRequestState] = React.useState<APIRequestState>('initial');
    const createAuthenticatedInitialRequestApi = useApi(createInitialRequestForAuthenticatedUser);
    const updateDraftRequestApi = useApi(updateDraftRequest);

    const [state, send] = useWizardMachine(history, location.pathname);
    const onSubmit = () => send(NEXT_EVENT);
    const goBack = () => send(PREV_EVENT);

    /**
     * By giving {process} in the function, the furthest step process will be changed into the process given in the
     * parameter.
     * Avoid using this in useEffect, because useEffect is run per default on the first render, before the subscribed
     * value changes. This mean, if we use this inside a useEffect and giving it a {process} parameter, the furthest step
     * in context will always be updated when the page first render, even if no value was changed.
     */
    const updateContext = React.useCallback(
        (payload: Partial<Context>, process?: SUBPROCESS_VALUE) => {
            const meta = process ? { ...getFurthestSteps(process), ...payload.meta } : undefined;
            if (meta) {
                return send({
                    type: 'UPDATE_CONTEXT',
                    value: { ...payload, meta },
                });
            }
            return send({
                type: 'UPDATE_CONTEXT',
                value: payload,
            });
        },
        [send],
    );

    React.useEffect(() => {
        const getMaterialCostAsync = async () => {
            if (materialList === undefined) {
                const materialPriceResponse = await getMaterialList();
                setMaterialList(materialPriceResponse);
            }
        };
        getMaterialCostAsync().catch((e) => console.error(e));
    }, [materialList]);

    React.useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    React.useEffect(
        () => {
            const statePath = state.context.meta.path;
            if (statePath !== undefined && location.pathname !== statePath) {
                send({ type: SYNC_STATE_WITH_LOCATION, path: location.pathname });
            }
        },
        // eslint-disable-next-line
        [location.pathname],
    );

    React.useEffect(
        () => {
            const statePath = state.context.meta.path;
            if (statePath !== undefined && location.pathname !== statePath) {
                send({ type: SYNC_LOCATION_WITH_STATE });
            }
        }, // eslint-disable-next-line
        [state.context.meta.path],
    );

    React.useEffect(() => {
        if (updateDraftRequestState === 'finished_successfully') {
            setTimeout(() => {
                setUpdateDraftRequestState('initial');
            }, 8000);
        }
    }, [updateDraftRequestState]);

    const saveDraft = React.useCallback(async () => {
        setUpdateDraftRequestState('loading');
        try {
            await saveDraftFunction({
                draftContext: state.context,
                updateContext,
                createInitialRequest: () => createAuthenticatedInitialRequestApi({ userType: state.context.userType }),
                updateDraftRequest: updateDraftRequestApi,
            });
            setUpdateDraftRequestState('finished_successfully');
        } catch (error) {
            console.error('Failed updating draft request', error);
            setUpdateDraftRequestState('error');
        }
    }, [state.context, updateContext, updateDraftRequestApi, createAuthenticatedInitialRequestApi]);

    const routeProperties: RouteProperties = {
        context: state.context,
        updateContext,
        goBack,
        onSubmit,
        saveDraft,
    };

    return (
        <>
            <div className="w-full my-10 px-0 lg:px-32" style={{ maxWidth: '1536px' }}>
                <WizardStep
                    steps={state.context.meta.steps}
                    furthestStep={[
                        state.context.meta.furthestStep.furthestProcess,
                        state.context.meta.furthestStep.furthestSubprocess,
                    ]}
                    hasError={state.value === 'nextSteps'}
                />
            </div>

            <Route path={SERVICE}>
                <Service {...routeProperties} />
            </Route>

            <Route path={CONSUMPTION}>
                <Consumption {...routeProperties} />
            </Route>

            <Route path={CONNECTION}>
                <Connection {...routeProperties} />
            </Route>

            <Route path={TRANSPOSITION}>
                <Transposition {...routeProperties} materialList={materialList} />
            </Route>

            <Route path={ADDRESSES}>
                <Addresses {...routeProperties} />
            </Route>

            <Route path={OFFER}>
                <Offer {...routeProperties} materialList={materialList} />
            </Route>

            <Route path={NEXT_STEPS}>
                <NextStepsForm {...routeProperties} />
            </Route>
            <ErrorBox initialShowError={updateDraftRequestState === 'error'} />
            <DraftSavedNotification
                isVisible={updateDraftRequestState === 'finished_successfully'}
                onClose={() => setUpdateDraftRequestState('initial')}
            />
        </>
    );
};

export default AppRouting;
