import { useMsal } from '@azure/msal-react';
import { InstallerSelectionForm } from '@ten-netzkundenportal/ui-installer-selection';
import * as React from 'react';
import { useState } from 'react';

import { useProcessGuidanceButtonsWithDraft } from '../../components/useProcessGuidanceButtonsWithDraft';
import { InstallerFilterResultWithDecodedCompanyName, RouteProperties } from '../../types';

export default ({ onSubmit, goBack, updateContext, context, saveDraft }: RouteProperties): React.ReactElement => {
    const { accounts } = useMsal();
    const [chosenInstallers, setChosenInstallers] = useState<InstallerFilterResultWithDecodedCompanyName[]>(
        context.chosenInstallers ?? [],
    );

    const installerSelectionDescription =
        context.userType === 'installer' || context.userType === 'projectpartner'
            ? 'Für die Umsetzung der Dienstleistung ist es erforderlich, einen Installateur einzubinden. Bitte wählen Sie dafür den Installateur aus, der von Ihrem Kunden beauftragt wurde.'
            : 'Für die Umsetzung der Dienstleistung ist es erforderlich, einen Installateur einzubinden. Bitte wählen Sie dafür den von Ihnen beauftragten Installateur aus.';
    const areNecessaryInstallersSelected = () => {
        if (context.power && !chosenInstallers.some((i) => i.electricityBranch)) {
            return false;
        }

        if (context.gas && !chosenInstallers.some((i) => i.gasBranch)) {
            return false;
        }

        return true;
    };

    const buttons = useProcessGuidanceButtonsWithDraft({
        saveDraft,
        onBack: goBack,
        onNext: onSubmit,
        isNextValid: areNecessaryInstallersSelected(),
    });

    return (
        <InstallerSelectionForm
            buttons={{
                prevButton: buttons.leftButton,
                nextButton: buttons.rightButtonGroup,
            }}
            chosenInstallers={chosenInstallers}
            setChosenInstallers={(installers) => {
                setChosenInstallers(installers);
                updateContext({ chosenInstallers: installers }, 'TRANSPOSITION_INSTALLER_SELECTION');
            }}
            electricity={context.power}
            gas={context.gas}
            texts={{ description: installerSelectionDescription }}
            installerId={
                context.userType === 'installer' && accounts.length > 0
                    ? `${accounts[0].idTokenClaims.extension_installerId}`
                    : undefined
            }
        />
    );
};
