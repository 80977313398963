import L from 'leaflet';

import { ToolType } from '../Toolbox';
import { DRAW_BUILDINGS_STATE, DRAW_MARKERS_STATE, EDIT_LINE_COURSE_STATE } from './useProcessGuide';

const isLayerBuilding = (layer) => layer instanceof L.Polygon;
const isLayerMarker = (layer) => layer instanceof L.Marker;
const isLayerLineCourse = (layer) => !(layer instanceof L.Polygon) && layer instanceof L.Polyline;

export default (
    map: L.Map,
    layers: L.Layer[],
    currentProcessStep: number,
    setCurrentTool: (toolType: ToolType) => void,
) => {
    setCurrentTool('none');
    // Making sure that marker options are set first prevents the map from misbehaving
    layers.sort((a, b) => {
        if (isLayerMarker(a) && !isLayerMarker(b)) return -1;
        if (!isLayerMarker(a) && isLayerMarker(b)) return 1;
        return 0;
    });

    layers.forEach((layer) => {
        if (isLayerBuilding(layer)) {
            const building = layer as L.Polygon;
            if (currentProcessStep === DRAW_BUILDINGS_STATE) {
                building.pm.setOptions({
                    allowEditing: true,
                    allowRemoval: true,
                    allowRotation: true,
                    draggable: true,
                });
            } else {
                building.pm.setOptions({
                    allowEditing: false,
                    allowRemoval: false,
                    allowRotation: false,
                    draggable: false,
                });
                building.pm.disable();
            }
        }

        if (isLayerMarker(layer)) {
            const marker = layer as L.Marker;
            if (currentProcessStep === DRAW_MARKERS_STATE) {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                marker['pm'].setOptions({
                    allowEditing: true,
                    allowRemoval: true,
                    draggable: true,
                });
            } else {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                marker['pm'].setOptions({
                    allowEditing: false,
                    allowRemoval: false,
                    draggable: false,
                });
            }
        }

        if (isLayerLineCourse(layer)) {
            const lineCourse = layer as L.Polyline;
            if (currentProcessStep === EDIT_LINE_COURSE_STATE) {
                lineCourse.pm.setOptions({
                    allowEditing: true,
                    allowRemoval: true,
                    allowRotation: true,
                    draggable: true,
                });
                map.pm.enableGlobalEditMode();
            } else {
                lineCourse.pm.setOptions({
                    allowEditing: false,
                    allowRemoval: false,
                    allowRotation: false,
                    draggable: false,
                });
            }
        }
    });
};
