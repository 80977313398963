import appConfig from '../app.config';
import { ApiParams } from '../hooks/useApi';
import { DraftDashboardInfo } from '../util/transformDraftRequestToDashboard';
import { Context } from '../wizard/context';

export type UpdateDraftRequestParams = {
    draftRequest: Partial<Context>;
    draftRequestDashboardInfos: DraftDashboardInfo;
    requestId: string;
};

export const updateDraftRequest = async ({
    customerId,
    axios,
    params,
}: ApiParams<UpdateDraftRequestParams>): Promise<void> => {
    const requestBody = {
        draft: params.draftRequest,
        draftDashboardInfos: params.draftRequestDashboardInfos,
    };

    await axios.put(
        `${appConfig.services.connectionRequestFormApi}/account/${customerId}/connection-request/${params.requestId}/draft`,
        requestBody,
    );
};
