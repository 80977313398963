import { Form, FormItem, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

import FormLayout from '../../components/SignupForm/FormLayout';
import { useProcessGuidanceButtonsWithDraft } from '../../components/useProcessGuidanceButtonsWithDraft';
import { RouteProperties, isBusinessDataCompany } from '../../types';
import CompanyInvoiceAddressForm from './CompanyInvoiceAddressForm';
import PrivateInvoiceAddressForm from './PrivateInvoiceAddressForm';

export default ({ onSubmit, goBack, updateContext, context, saveDraft }: RouteProperties): React.ReactElement => {
    const { userType } = context;

    const [isDeviatingInvoiceAddress, setIsDeviatingInvoiceAddress] = React.useState(
        context.isDeviatingInvoiceAddress ?? false,
    );

    const [isFormValid, setIsFormValid] = React.useState(false);

    const [deviatingInvoiceAddressType, setDeviatingInvoiceAddressType] = React.useState(
        context.deviatingInvoiceAddressType ?? 'private',
    );

    React.useEffect(() => {
        updateContext({ isDeviatingInvoiceAddress }, 'ADDRESSES_INVOICE_ADDRESS');
    }, [isDeviatingInvoiceAddress, updateContext]);

    React.useEffect(() => {
        updateContext({ deviatingInvoiceAddressType }, 'ADDRESSES_INVOICE_ADDRESS');
    }, [deviatingInvoiceAddressType, updateContext]);

    const addressFormQuestion = () =>
        isDeviatingInvoiceAddress ? 'Geben Sie bitte Ihre gewünschte Rechnungsadresse ein:' : '';

    const createPrivateInvoiceAddressForm = () => (
        <PrivateInvoiceAddressForm
            invoiceAddressQuestion={addressFormQuestion()}
            updateContext={updateContext}
            context={context}
            setIsFormValid={setIsFormValid}
        />
    );

    const createCompanyInvoiceAddressForm = () => (
        <CompanyInvoiceAddressForm
            invoiceAddressQuestion={addressFormQuestion()}
            updateContext={updateContext}
            context={context}
            setIsFormValid={setIsFormValid}
        />
    );

    const formBasedOnUserType = (): React.ReactElement => {
        switch (userType) {
            case 'private':
                return createPrivateInvoiceAddressForm();
            case 'company':
                return createCompanyInvoiceAddressForm();
            case 'installer':
            case 'projectpartner':
                if (isBusinessDataCompany(context)) {
                    return createCompanyInvoiceAddressForm();
                }
                return createPrivateInvoiceAddressForm();
            default:
                throw Error('No valid customer type');
        }
    };

    const formBasedOnUserChoice = (): React.ReactElement => {
        switch (context.deviatingInvoiceAddressType) {
            case 'private':
                return createPrivateInvoiceAddressForm();
            case 'company':
                return createCompanyInvoiceAddressForm();
            default:
                throw Error('No valid customer type');
        }
    };

    const { leftButton, rightButtonGroup } = useProcessGuidanceButtonsWithDraft({
        saveDraft,
        onBack: goBack,
        onNext: onSubmit,
        isNextValid: isFormValid,
    });

    return (
        <Form title="Rechnungsadresse" leftButton={leftButton} rightButton={rightButtonGroup}>
            <FormLayout>
                <FormItem label="Möchten Sie eine abweichende Rechnungsadresse angeben?">
                    <div className="flex w-72 children-w-full">
                        <RadioButton
                            name="invoiceSelection"
                            id="invoiceSelection-yes"
                            value="yes"
                            checked={isDeviatingInvoiceAddress}
                            onChange={() => setIsDeviatingInvoiceAddress(true)}
                        >
                            ja
                        </RadioButton>
                        <RadioButton
                            name="invoiceSelection"
                            id="invoiceSelection-no"
                            value="no"
                            checked={!isDeviatingInvoiceAddress}
                            onChange={() => setIsDeviatingInvoiceAddress(false)}
                        >
                            nein
                        </RadioButton>
                    </div>
                </FormItem>
                {context.isDeviatingInvoiceAddress && (
                    <FormItem label="Handelt es sich um eine private oder geschäftliche Rechnungsadresse?">
                        <div className="flex w-72 children-w-full">
                            <RadioButton
                                name="deviatingAddressType"
                                id="deviatingAddressType-private"
                                value="private"
                                checked={deviatingInvoiceAddressType === 'private'}
                                onChange={() => setDeviatingInvoiceAddressType('private')}
                            >
                                privat
                            </RadioButton>

                            <RadioButton
                                name="deviatingAddressType"
                                id="deviatingAddressType-company"
                                value="company"
                                checked={deviatingInvoiceAddressType === 'company'}
                                onChange={() => setDeviatingInvoiceAddressType('company')}
                            >
                                geschäftlich
                            </RadioButton>
                        </div>
                    </FormItem>
                )}
            </FormLayout>
            {context.isDeviatingInvoiceAddress ? formBasedOnUserChoice() : formBasedOnUserType()}
        </Form>
    );
};
