import { CheckThick, IconsCloseMenu } from '@ten-netzkundenportal/ui-components';
import React from 'react';

type DraftSavedNotificationProps = {
    isVisible: boolean;
    onClose: () => void;
};

export const DraftSavedNotification = ({ isVisible, onClose }: DraftSavedNotificationProps) => (
    <div
        data-testid="draft-notification"
        className={`w-96 text-left bg-white ease-in-out delay-150 duration-500 transition-all absolute flex gap-x-4 bottom-28 right-0 p-4 shadow-ten text-small ${
            isVisible ? 'opacity-100 z-[1000]' : 'opacity-0 -z-20'
        }`}
    >
        <div className="flex gap-x-4">
            <div className="flex items-center">
                <CheckThick className="fill-current text-electricity-green w-6 h-6" />
            </div>
            <div className="flex flex-col gap-y-1">
                <b>Ihr Antrag wurde als Entwurf gespeichert.</b>
                <span>Sie können den Antrag zu einem späteren Zeitpunkt in Ihrer Übersicht fortsetzen.</span>
            </div>
        </div>
        <button
            type="button"
            aria-label="close-button"
            className="absolute fill-current text-dark-grey hover:text-darkest-grey top-0 right-0 w-4 mt-3 mr-3"
            onClick={onClose}
        >
            <IconsCloseMenu />
        </button>
    </div>
);
