import { createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import {
    ConnectionSectionGas,
    InfoTextForLocation,
    ObjectOfContract,
    OfferTemplate,
    PropertyBoundarySection,
} from '@ten-netzkundenportal/ui-contract-offer/';
import * as React from 'react';

import LocationSection from '../../components/offer/LocationSection';
import { GasOffer } from '../../types';
import { addressToString } from '../../util';
import { OfferProperties } from '../Offer';
import defineApplicantName from '../defineApplicantName';
import { findCorrectAddress, getHint, getPersonalContribution } from '../util';

export type OfferGasFormProps = {
    gasOffer: GasOffer;
};

export default ({
    onSubmit,
    goBack,
    context,
    gasOffer,
    materialList,
}: OfferGasFormProps & OfferProperties): React.ReactElement => {
    const personalContribution = getPersonalContribution(
        false,
        materialList,
        context.personalContributionMultiEntryGas,
        context.personalContributionCivilEngineeringGas,
    );

    const objectOfContract = (
        <ObjectOfContract
            location={<LocationSection location={context.location} />}
            connectionLengthSection={
                <ConnectionSectionGas
                    connectionLengthCadastralDistrictToBuilding={gasOffer.connectionLengthCadastralDistrictToBuilding}
                    connectionLengthMainLineToCadastralDistrict={gasOffer.connectionLengthMainLineToCadastralDistrict}
                />
            }
            propertyBoundarySection={<PropertyBoundarySection offerType="gas" />}
            infoTextForLocationOfConnectionObject={<InfoTextForLocation offerType="gas" />}
            landOwner={context.isLandOwner === 'yes'}
            consumptionOutputLimit={30}
        />
    );

    const name = defineApplicantName(context);
    const address = findCorrectAddress(context);

    const { prevButton, nextButton } = createProcessGuidanceButtons({
        onBack: goBack,
        onNext: onSubmit,
        isNextValid: true,
    });

    return (
        <OfferTemplate
            leftButton={prevButton}
            rightButton={nextButton}
            name={name}
            address={addressToString(address)}
            automaticOffer={gasOffer}
            offerTypeName="Erdgas"
            netName="Erdgasnetz"
            hint={getHint('gas')}
            personalContribution={context.personalContribution === 'yes' ? personalContribution : []}
            objectOfContract={objectOfContract}
            userType={context.userType}
        />
    );
};
