import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as singleSpa from 'single-spa';

import { LOCATION_DATA, SERVICE_SELECTION } from '../routes';
import { RouteProperties } from '../types';
import LocationDataForm from './location-data-form';
import ServiceSelectionForm from './service-selection-form';

export default (routeProperties: RouteProperties): React.ReactElement => (
    <Switch>
        <Route path={LOCATION_DATA}>
            <LocationDataForm
                {...routeProperties}
                goBack={() => singleSpa.navigateToUrl('/netzanschluss/antragsstrecke/startseite')}
            />
        </Route>

        <Route path={SERVICE_SELECTION}>
            <ServiceSelectionForm {...routeProperties} />
        </Route>
    </Switch>
);
