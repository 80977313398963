import { Button, CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

import useApi from '../hooks/useApi';
import { DASHBOARD } from '../routes';
import { APIRequestState } from '../types';
import { getDraftRequest } from './api/getDraftRequest';
import { contextMapper } from './contextMapper';

export const LandingPage = () => {
    const history = useHistory();

    const { requestId } = useParams<{ requestId: string }>();
    const getDraftRequestApi = useApi(getDraftRequest);

    const [apiState, setApiState] = React.useState<APIRequestState>('initial');

    const getDraftRequestAndFillContext = React.useCallback(async () => {
        const draftRequest = await getDraftRequestApi({
            requestId,
        });

        const context = contextMapper(draftRequest);

        localStorage.setItem('antragsstrecke', JSON.stringify(context));
        history.push(context.meta.path);
    }, [getDraftRequestApi, history, requestId]);

    React.useEffect(() => {
        if (apiState === 'initial') {
            setApiState('loading');
            getDraftRequestAndFillContext()
                .then(() => setApiState('finished_successfully'))
                .catch((error) => {
                    setApiState('error');
                    console.error('Failed to load draft request', error);
                });
        }
    }, [getDraftRequestAndFillContext, apiState]);

    const backButton = (
        <Button
            label="Zur Übersicht"
            type="secondary"
            id="goBack"
            additionalClassName="mt-8"
            onClick={() => navigateToUrl(DASHBOARD)}
        />
    );

    if (apiState === 'error') {
        return (
            <div className="mt-10 grid gap-y-5 w-full">
                <span>
                    Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                    erneut.
                </span>
                {backButton}
            </div>
        );
    }

    return <CenteredLoadingSpinner />;
};
