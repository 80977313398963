import * as React from 'react';
import { useMap } from 'react-leaflet';

import disabledConnectionIcon from '../../../assets/disabledConnectionIcon.svg';
import { ConnectionDataProperties } from '../../../types';
import { MarkerToolType, getMarkerOptions, iconMap } from '../../../util/drawing';
import { ToolType, activeButtonStyle, disabledButtonStyle, inactiveButtonStyle } from '../Toolbox';

const titleMap = { Electricity: 'Stromanschluss', Gas: 'Erdgasanschluss', MultiEntry: 'Mehrspartenanschluss' };
export type MarkerButtonProperties = {
    context: Partial<ConnectionDataProperties>;
    buttonType: MarkerToolType;
    currentTool: ToolType;
    setCurrentTool: React.Dispatch<React.SetStateAction<ToolType>>;
    disabled: boolean;
};

export default ({
    context,
    buttonType,
    currentTool,
    setCurrentTool,
    disabled,
}: MarkerButtonProperties): React.ReactElement => {
    const map = useMap();
    const getCorrectMarker = (mT: MarkerToolType) => {
        switch (mT) {
            case 'Electricity':
                return context.powerMarker;
            case 'Gas':
                return context.gasMarker;
            case 'MultiEntry':
                return context.multiEntryMarker;
            default:
                return undefined;
        }
    };

    const isButtonEnabled = getCorrectMarker(buttonType) === undefined && !disabled;

    const title = titleMap[buttonType];
    const icon = isButtonEnabled ? iconMap[buttonType] : disabledConnectionIcon;

    let buttonStyle = disabledButtonStyle;
    if (isButtonEnabled) {
        buttonStyle = currentTool === buttonType ? activeButtonStyle : inactiveButtonStyle;
    }

    return (
        <button
            title={title}
            className={buttonStyle}
            type="button"
            aria-label="connection marker"
            onClick={
                isButtonEnabled
                    ? () => {
                          if (currentTool === buttonType) {
                              map.pm.disableDraw();
                              setCurrentTool('none');
                          } else {
                              map.pm.enableDraw('Marker', {
                                  markerStyle: getMarkerOptions(buttonType),
                              });
                              setCurrentTool(buttonType);
                          }
                      }
                    : undefined
            }
        >
            <div className="w-6 h-6 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url('${icon}')` }} />
        </button>
    );
};
