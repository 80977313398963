import { AxiosResponse } from 'axios';

import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';
import { Context } from '../../wizard/context';

export const getDraftRequest = async ({
    customerId,
    axios,
    params,
}: ApiParams<{ requestId: string }>): Promise<Partial<Context>> => {
    const response: AxiosResponse<Partial<Context>> = await axios.get(
        `${appConfig.services.connectionRequestFormApi}/account/${customerId}/connection-request/${params.requestId}/draft`,
    );

    return response.data;
};
