import { Context } from '../../wizard';

export const transformContextToBuilding = (context: Partial<Context>) => {
    const { building } = context;

    return {
        buildingType: building.buildingType,
        specialType: building.buildingType === 'special' ? building.specialType : undefined,
        newBuilding: building.newBuilding === 'yes',
        newBuildingName:
            building.newBuilding === 'yes' && building.newBuildingName?.trim()
                ? building.newBuildingName?.trim()
                : undefined,
        basement:
            building.buildingType === 'singleFamily' || building.buildingType === 'multiFamily'
                ? building.basement === 'yes'
                : false,
        residentialUnit: building.buildingType === 'multiFamily' ? building.residentialUnit : undefined,
        commercialUnit: building.buildingType === 'multiFamily' ? building.commercialUnit : undefined,
        communityFacilities: building.buildingType === 'multiFamily' ? building.communityFacilities : undefined,
    };
};
