import { EmailInputField, FormItem, InfoI } from '@ten-netzkundenportal/ui-components';
import { validateEmailHasNoAccountOrAccountWithCustomerType } from '@ten-netzkundenportal/ui-registration';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { BusinessData } from '../../types';

const EmailAddressArea = (): React.ReactElement => {
    const {
        register,
        watch,
        formState: { errors },
    } = useFormContext<BusinessData>();

    const customerType = watch('businessData.customerType');

    const label = (
        <div className="relative">
            <span>Geben Sie bitte die E-Mail-Adresse Ihres Kunden an:</span>
            <div
                className="inline-block w-3 overflow-visible fill-current text-primary
                        hover:text-dark-blue absolute -right-2 top-[0.35rem]"
            >
                <InfoI text="Bitte verwenden Sie die E-Mail-Adresse, die Ihr Kunde zur Anmeldung im Netzkundenportal verwendet oder verwenden möchte." />
            </div>
        </div>
    );

    return (
        <FormItem label={label}>
            <div className="max-w-[456px]">
                <EmailInputField
                    id="businessData.email"
                    register={register}
                    displayErrorMessage={errors?.businessData?.email?.message}
                    additionalEmailValidation={(email, signal) =>
                        validateEmailHasNoAccountOrAccountWithCustomerType({ email, signal, customerType })
                    }
                />
            </div>
        </FormItem>
    );
};
export default EmailAddressArea;
