import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import axios from 'axios';

import appConfig from '../app.config';
import { ApiParams } from '../hooks/useApi';
import { Params } from '../types/Params';

const serviceUrl = `${appConfig.services.connectionRequestFormApi}`;

type Response = {
    id: string;
};

export type CreateInitialRequestReturnType = {
    connectionRequestId: string;
};

export const createInitialRequestForAuthenticatedUser = async ({
    customerId,
    axios: axiosInstance,
    params,
}: ApiParams<{ userType: CustomerType }>): Promise<CreateInitialRequestReturnType> => {
    const { data } = await axiosInstance.post<Response>(`${serviceUrl}/customer/${customerId}/connection-request`, {
        userType: params.userType,
    });

    return {
        connectionRequestId: data.id,
    };
};

export const createInitialRequestForUnauthenticatedUser = async ({
    params,
}: Params<{ userType: CustomerType }>): Promise<CreateInitialRequestReturnType> => {
    const { data } = await axios.post<Response>(`${serviceUrl}/connection-request`, {
        userType: params.userType,
    });

    return {
        connectionRequestId: data.id,
    };
};
