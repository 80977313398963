export const ROOT = '/netzanschluss/antragsstrecke';
export const REQUEST_ROOT = '/netzanschluss/antrag';

export const LANDING_PAGE = `${ROOT}/entwurf-antrag/:requestId`;

export const MAINTENANCE = `${ROOT}/wartung`;

export const NOT_RESPONSIBLE = `${ROOT}/zustaendigkeit`;
export const START = `${ROOT}/startseite`;

export const SERVICE = `${ROOT}/service`;
export const LOCATION_DATA = `${SERVICE}/standortdaten`;
export const SERVICE_SELECTION = `${SERVICE}/serviceauswahl`;

export const CONSUMPTION = `${ROOT}/leistungsbedarf`;
export const BUILDING_DATA = `${CONSUMPTION}/gebaeudedaten`;
export const POWER_CONSUMPTION_DECLARATION = `${CONSUMPTION}/strom-angaben`;
export const POWER_CONSUMPTION_TOTAL = `${CONSUMPTION}/strom-gesamt`;
export const GAS_CONSUMPTION = `${CONSUMPTION}/gas`;

export const CONNECTION = `${ROOT}/anschluss`;
export const CONNECTION_DATA = `${CONNECTION}/daten`;
export const CONNECTION_PLAN = `${CONNECTION}/plan`;
export const LAND_OWNER = `${CONNECTION}/eigentuemer`;

export const TRANSPOSITION = `${ROOT}/umsetzung`;
export const PERSONAL_CONTRIBUTION = `${TRANSPOSITION}/eigenleistung`;
export const INSTALLER_SELECTION = `${TRANSPOSITION}/installateur`;

export const ADDRESSES = `${ROOT}/adressen`;
export const CONTACT_ADDRESS = `${ADDRESSES}/kunde`;
export const INVOICE_ADDRESS = `${ADDRESSES}/rechnung`;

export const OFFER = `${ROOT}/angebot`;
export const REQUEST_OVERVIEW = `${OFFER}/antragsuebersicht`;
export const OFFER_POWER = `${OFFER}/strom`;
export const OFFER_GAS = `${OFFER}/gas`;
export const OFFER_CONCLUSION = `${OFFER}/abschluss`;
export const OFFER_CONCLUSION_REDIRECT = `${OFFER}/abschluss/:id`;

export const OFFER_REVISITED = `${REQUEST_ROOT}/:connectionRequestId/angebot/:processCommunicationId`;
export const OFFER_REVISITED_OVERVIEW = `${OFFER_REVISITED}/uebersicht`;
export const OFFER_REVISITED_DOCUMENT = `${OFFER_REVISITED}/dokumente`;
export const OFFER_REVISITED_DOCUMENT_UPLOAD = `${OFFER_REVISITED}/dokumente-hochladen`;
export const OFFER_REVISITED_CONCLUSION = `${OFFER_REVISITED}/abschluss`;
export const OFFER_REVISITED_INSTALLER_SELECTION = `${OFFER_REVISITED}/installateur-auswahl`;

export const NEXT_STEPS = `${ROOT}/naechste-schritte`;

export const DASHBOARD = '/uebersicht';
