import { createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import {
    ConnectionSectionElectricity,
    InfoTextForLocation,
    ObjectOfContract,
    OfferTemplate,
    PropertyBoundarySection,
} from '@ten-netzkundenportal/ui-contract-offer/';
import * as React from 'react';

import LocationSection from '../../components/offer/LocationSection';
import { ElectricityOffer } from '../../types';
import { addressToString } from '../../util';
import { OfferProperties } from '../Offer';
import defineApplicantName from '../defineApplicantName';
import { findCorrectAddress, getHint, getPersonalContribution } from '../util';

export type OfferPowerFormProps = {
    electricityOffer: ElectricityOffer;
};

export default ({
    onSubmit,
    goBack,
    context,
    electricityOffer,
    materialList,
}: OfferPowerFormProps & OfferProperties): React.ReactElement => {
    const personalContribution = getPersonalContribution(
        true,
        materialList,
        context.personalContributionMultiEntryPower,
        context.personalContributionCivilEngineeringPower,
        context.personalContributionCounter,
    );

    const objectOfContract = (
        <ObjectOfContract
            location={<LocationSection location={context.location} />}
            connectionLengthSection={
                <ConnectionSectionElectricity connectionLength={electricityOffer.connectionLength} />
            }
            propertyBoundarySection={<PropertyBoundarySection offerType="electricity" />}
            infoTextForLocationOfConnectionObject={<InfoTextForLocation offerType="electricity" />}
            landOwner={context.isLandOwner === 'yes'}
            consumptionOutputLimit={30}
        />
    );

    const name = defineApplicantName(context);
    const address = findCorrectAddress(context);

    const { prevButton, nextButton } = createProcessGuidanceButtons({
        onBack: goBack,
        onNext: onSubmit,
        isNextValid: true,
    });

    return (
        <OfferTemplate
            leftButton={prevButton}
            rightButton={nextButton}
            name={name}
            address={addressToString(address)}
            automaticOffer={electricityOffer}
            offerTypeName="Strom"
            netName="Niederspannungsnetz"
            hint={getHint('electricity')}
            personalContribution={context.personalContribution === 'yes' ? personalContribution : []}
            objectOfContract={objectOfContract}
            userType={context.userType}
        />
    );
};
