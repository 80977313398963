import {
    TeagPiktoSkizzewerkzeug01800X800220110 as DragIcon,
    TeagPiktoSkizzenwerkzeug05800X8002205021 as EditIcon,
    TeagPiktoSkizzenwerkzeug03800X800220502 as FreehandIcon,
    TeagPiktoSkizzenwerkzeug04800X8002205021 as RectangleIcon,
    TeagPiktoSkizzewerkzeug02800X800220110 as RotateIcon,
    Trash,
} from '@ten-netzkundenportal/ui-components';
import L, { PM } from 'leaflet';
import * as React from 'react';
import { useMap } from 'react-leaflet';

import { disableAllEditingModesOnMap } from '../../../util/drawing';
import { ToolType, activeButtonStyle, disabledButtonStyle, inactiveButtonStyle } from '../Toolbox';
import { DRAW_BUILDINGS_STATE, DRAW_MARKERS_STATE } from './useProcessGuide';

const drawOptions: PM.DrawModeOptions = {
    tooltips: false,
    cursorMarker: true,
    templineStyle: {
        radius: 10,
        color: 'black',
        weight: 1,
    },
    hintlineStyle: {
        color: 'black',
        weight: 1,
        dashArray: '4',
    },
};

export type ToolButtonProperties = {
    buttonType: 'Freehand' | 'Rectangle' | 'Rotate' | 'Drag' | 'Edit' | 'Remove';
    currentTool: ToolType;
    setCurrentTool: React.Dispatch<React.SetStateAction<ToolType>>;
    currentProcessStep: number;
};

const isLayerBuilding = (layer) => layer instanceof L.Polygon;
const isLayerMarker = (layer) => layer instanceof L.Marker;

const getBuildings = (map) =>
    L.PM.Utils.findLayers(map)
        .filter((layer) => isLayerBuilding(layer))
        .map((building) => building as L.Polygon);
const getMarkers = (map) =>
    L.PM.Utils.findLayers(map)
        .filter((layer) => isLayerMarker(layer))
        .map((marker) => marker as L.Marker);

export default ({
    buttonType,
    currentTool,
    setCurrentTool,
    currentProcessStep,
}: ToolButtonProperties): React.ReactElement => {
    const map = useMap();

    const disabled = () => {
        switch (buttonType) {
            case 'Freehand':
                return currentProcessStep !== DRAW_BUILDINGS_STATE;
            case 'Rectangle':
                return currentProcessStep !== DRAW_BUILDINGS_STATE;
            case 'Rotate':
                return currentProcessStep !== DRAW_BUILDINGS_STATE;
            case 'Drag':
                return currentProcessStep !== DRAW_BUILDINGS_STATE && currentProcessStep !== DRAW_MARKERS_STATE;
            case 'Edit':
                return currentProcessStep !== DRAW_BUILDINGS_STATE;
            case 'Remove':
                return currentProcessStep !== DRAW_BUILDINGS_STATE && currentProcessStep !== DRAW_MARKERS_STATE;
            default:
                return false;
        }
    };

    const titleMap = {
        Freehand: 'Frei zeichnen',
        Rectangle: 'Rechteck zeichnen',
        Rotate: 'Drehen',
        Drag: 'Verschieben',
        Edit: 'Anpassen',
        Remove: 'Löschen',
    };

    const iconMap = {
        Freehand: <FreehandIcon height="40px" className="fill-current" />,
        Rectangle: <RectangleIcon height="40px" className="fill-current" />,
        Rotate: <RotateIcon height="24px" className="fill-current" />,
        Drag: <DragIcon height="24px" className="fill-current" />,
        Edit: <EditIcon height="40px" className="fill-current" />,
        Remove: <Trash height="24px" className="fill-current" />,
    };

    const functionMap = {
        Freehand: () => {
            if (currentTool === 'Freehand') {
                setCurrentTool('none');
            } else {
                map.pm.enableDraw('Polygon', drawOptions);
                setCurrentTool('Freehand');
            }
        },
        Rectangle: () => {
            if (currentTool === 'Rectangle') {
                setCurrentTool('none');
            } else {
                map.pm.enableDraw('Rectangle', drawOptions);
                setCurrentTool('Rectangle');
            }
        },
        Rotate: () => {
            if (currentTool === 'Rotate') {
                setCurrentTool('none');
            } else {
                getBuildings(map).forEach((building) => building.pm.enableRotate());
                setCurrentTool('Rotate');
            }
        },
        Drag: () => {
            if (currentTool === 'Drag') {
                setCurrentTool('none');
            } else {
                if (currentProcessStep === DRAW_BUILDINGS_STATE) {
                    getBuildings(map).forEach((building) => building.pm.enableLayerDrag());
                }
                if (currentProcessStep === DRAW_MARKERS_STATE) {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    getMarkers(map).forEach((marker) => marker['pm'].enableLayerDrag());
                }
                setCurrentTool('Drag');
            }
        },
        Edit: () => {
            if (currentTool === 'Edit') {
                setCurrentTool('none');
            } else {
                getBuildings(map).forEach((building) => building.pm.enable());
                setCurrentTool('Edit');
            }
        },
        Remove: () => {
            if (currentTool === 'Remove') {
                setCurrentTool('none');
            } else {
                map.pm.enableGlobalRemovalMode();
                setCurrentTool('Remove');
            }
        },
    };

    let buttonStyle = inactiveButtonStyle;
    if (currentTool === buttonType) {
        buttonStyle = activeButtonStyle;
    }
    if (disabled()) {
        buttonStyle = disabledButtonStyle;
    }

    return (
        <button
            title={titleMap[buttonType]}
            className={buttonStyle}
            type="button"
            onClick={() => {
                if (!disabled()) {
                    disableAllEditingModesOnMap(map);
                    functionMap[buttonType]();
                }
            }}
        >
            {iconMap[buttonType]}
        </button>
    );
};
