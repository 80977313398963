import { FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { TwoButton } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { useProcessGuidanceButtonsWithDraft } from '../../components/useProcessGuidanceButtonsWithDraft';
import { GasConsumptionInKw, RouteProperties } from '../../types';
import { useContextUpdate } from '../../wizard';

export default ({ onSubmit, goBack, updateContext, context, saveDraft }: RouteProperties): React.ReactElement => {
    const reactHookForm = useForm<GasConsumptionInKw>({
        mode: 'onTouched',
        defaultValues: {
            gasConsumptionInKw: context.gasConsumptionInKw,
        },
        shouldUnregister: true,
    });
    const {
        watch,
        handleSubmit,
        formState: { isValid, errors },
    } = reactHookForm;

    useContextUpdate(watch, updateContext, 'CONSUMPTION_GAS_CONSUMPTION');
    const { leftButton, rightButtonGroup } = useProcessGuidanceButtonsWithDraft({
        saveDraft,
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        isNextValid: isValid,
    });

    return (
        <>
            <FormLayout>
                <FormItem
                    label={
                        context.userType === 'installer' || context.userType === 'projectpartner'
                            ? 'Welche Leistung wird Ihr Kunde insgesamt benötigen?'
                            : 'Welche Leistung werden Sie insgesamt benötigen?'
                    }
                    className="items-center gap-x-10 grid-cols-[1fr_4fr]"
                >
                    <div className="flex">
                        <NumberInput
                            name="consumption"
                            {...NumberInputFunctions.registerNumberInput(reactHookForm, 'gasConsumptionInKw', {
                                required: true,
                                shouldUnregister: true,
                                min: 1,
                            })}
                            displayErrorMessage={
                                errors && errors.gasConsumptionInKw
                                    ? 'Bitte geben Sie eine Zahl größer als 0 ein.'
                                    : undefined
                            }
                        />
                        <span className="ml-2 mt-2">kW</span>
                    </div>
                </FormItem>
            </FormLayout>
            <TwoButton leftButton={leftButton} rightButton={rightButtonGroup} />
        </>
    );
};
